import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/admin";

export const adminReportingApiSlice = createApi({
  reducerPath: "adminReportingApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 제보 리스트 가져오기
    getWhistleList : builder.mutation({
      query : (param : {
        workplace : string, whistleblowType : any, wbStatus : any, whistleBlowFromMdate : any, whistleBlowToMdate : any, page:any,size:number,keyword:string,sortColumn:string,sortOrder:string
      }) => {
        return{
          url : `/whistle/getWhistleList.php?workplace=${param.workplace}&whistleblowType=${param.whistleblowType}&wbStatu=${param.wbStatus}&whistleBlowFromMdate=${param.whistleBlowFromMdate}&whistleBlowToMdate=${param.whistleBlowToMdate}&page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}`
        }
      }
    }),
    // 제보 내용 상세보기
    getWhistleDetail : builder.mutation({
        query : (param : {id: any}) => {
            return{
                url: `/whistle/getWhistleDetail.php?ID=${param.id}`
            }
        }
    }),
    // 제보하기 상태 변경
    updWhistleblowReplyStatus :builder.mutation({
      query : (body : any) => {
        return{
          url : '/whistle/updWhistleblowReplyStatus.php', method : 'post', body
        }
      }
    }),
    // 제보하기 삭제
    delWhistle : builder.mutation({
      query : (body : any) => {
        return{
          url : `/whistle/delWhistleblow.php`, method : 'post', body
        }
      }
    }),
    // 문의하기 리스트 불러오기
    getInquiry : builder.mutation({
      query : (param : {groupCode : any , page : any, size : number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/inquiry/getInquiry.php?groupCode=${param.groupCode}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    // 문의 내용 상세보기
    getInquiryDetail : builder.mutation({
      query : (param : {idx : string}) => {
        return{
          url : `/inquiry/getInquiryDetails.php?inquiry_idx=${param.idx}`
        }
      }
    }),
    // 문의하기 상태 변경
    updInquiryReplyStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/inquiry/updInquiryReplyStatus.php', method : 'post', body
        }
      }
    }),
    // 관리자 의견 남기기
    setWhistleblowComment : builder.mutation({
      query : (body : any) => {
        return{
          url : '/whistle/setWhistleblowReply.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetWhistleListMutation, useGetWhistleDetailMutation, useGetInquiryMutation, useGetInquiryDetailMutation, useUpdInquiryReplyStatusMutation,
  useUpdWhistleblowReplyStatusMutation, useDelWhistleMutation, useSetWhistleblowCommentMutation
} = adminReportingApiSlice;
